<template>
  <div>
    <div>
      <b-card no-body style="padding: 10px">
        <b-form-row>
          <b-col>
            <b-button
              style="float: right"
              class="base-button"
              variant="success"
              @click="onRegend"
              >{{ detailCtrlInfo.ordinary.label }}</b-button
            >
            <div class="trucks">
              <GanttVehicle
                class="truckSt"
                v-for="(vehicle, index) in ganttChartVehicleList"
                :key="index"
                :ganttChartVehicle="vehicle"
              >
              </GanttVehicle>
            </div>
            <GanttChart
              :ganttChartVehicleList="ganttChartVehicleList"
              :startDate="startDate"
              :endDate="ganttEndDate"
            />
          </b-col>
        </b-form-row>
      </b-card>
    </div>
    <GanttRegendModal id="gantt-regend-modal" type="entry" />
  </div>
</template>

<script>
import { openSubModal, getDateFromDateString8 } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
const screenId = SCREEN_ID.GANTT_VIEW

export default {
  components: {
    GanttChart: () => import('@/plan/manualPlanning/gantt/GanttChart.vue'),
    GanttRegendModal: () =>
      import('@/plan/manualPlanning/gantt/GanttRegendModal.vue'),
    GanttVehicle: () => import('@/plan/manualPlanning/gantt/GanttVehicle.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    ganttChartVehicleList: {
      type: Array,
      default: () => null,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      // endDate: '',
    }
  },
  computed: {
    ganttEndDate() {
      let beginDate = getDateFromDateString8(this.startDate)
      // let endDate = getDateFromDateString8(this.endDate)
      // let dateDiff = Math.floor((endDate - beginDate) / (1000 * 60 * 60 * 24))
      // if (dateDiff >= 2) {
      //   endDate = beginDate
      //   endDate.setDate(endDate.getDate() + 2)
      // } else {
      //   endDate.setDate(endDate.getDate() + 1)
      // }
      let endDate = beginDate
      endDate.setDate(endDate.getDate() + 2)

      let year = endDate.getFullYear()
      let month = 1 + endDate.getMonth()
      month = month >= 10 ? month : '0' + month
      let day = endDate.getDate()
      day = day >= 10 ? day : '0' + day
      return year + '' + month + '' + day
    },

    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
  },
  methods: {
    onRegend() {
      openSubModal(this, 'gantt-regend-modal')
    },
  },
}
</script>
<style scoped>
.trucks {
  float: left;
}
.truckSt {
  width: 130px;
  height: 12px;
}
.base-button {
  font-size: 0.8rem;
  margin-right: 4px;
  height: 32px;
}
</style>
